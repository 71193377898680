<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {}
</script>

<style>
/* 公共css */
@import './assets/css/common.css';
</style>
